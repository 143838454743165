#product {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
}

#product .product {
    display: flex;
    width: 90%;
    height: 100%;
    margin: auto;
    flex-direction: column;
    gap: 1rem;
}

#product .product h2 {
    color: #5a070b;
    position: relative;
    width: max-content;
    padding-bottom: 5px;
    font-size: 3rem;
    top: 3rem;
}

#product .product h2 span {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #e8c56d;
    border-radius: 50%;
}

.product .product_all {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.product .product_all div {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #5a070b;
}

.product .product_all .product_2,
.product .product_all .product_5 {
    margin-bottom: 10rem;
}


@media (max-width: 768px) {
    .product .product_all div {
        width: 9rem;
        font-size: 13px;
    }

    .product .product_all .product_2,
    .product .product_all .product_5 {
        margin-bottom: 0;
    }

    #product .product h2 {
        top: 0;
        margin: 1rem 0;
    }

    .product .product_all h3 {
        font-size: 14px;
    }

}