*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: #b7b7b7;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 20px;
}

img,
video {
  width: 100%;
}

body {
  direction: rtl;
  overflow-x: hidden;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* hidden_all_items */
.hidden_all_items {
  transform: scale(0);
}

.show_all_items {
  animation: show-all_items 1s ease-in-out;
}

@keyframes show-all_items {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

/* END hidden_all_items */