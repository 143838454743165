#about {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

#about .about_us {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    padding: 5rem;
}

#about .about_us .text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#about .about_us .text h1 {
    color: #5a070b;
    position: relative;
    width: max-content;
    padding-bottom: 5px;
}

#about .about_us .text p {
    color: #5a070b;
    font-size: 2rem;
}

#about .about_us .text h1 span {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #e8c56d;
    border-radius: 50%;
}

#about .about_us img {
    grid-area: 1 / 2;
}

.full_address {
    position: relative;
    width: 100%;
}

#about .address {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    justify-items: center;
    background: #5a070b url("../../img/texture.png") no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1rem;
    width: 90%;
    margin: auto;
    gap: 5rem;
}

#about .full_address .address_2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 15rem;
}

#about .full_address .address_3 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 15rem;
    z-index: -1;
}

#about .address img {
    grid-area: 1 / 2;
    border-radius: 1rem;
    max-height: 40rem;
    object-fit: cover;
    object-position: top;
}

#about .address .text_address {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
}

#about .address .text_address h2 {
    color: #fff;
    position: relative;
    width: max-content;
    padding-bottom: 5px;
    font-size: 3rem;
}

#about .address .text_address p {
    color: #fff;
    font-size: 2rem;
    text-align: justify;
}

#about .address .text_address h2 span {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #e8c56d;
    border-radius: 50%;
}

#about .address .text_address div {
    display: flex;
}

#about .address .text_address div svg {
    color: #fff;
    font-size: 2rem;
    margin: 10px;
    margin-top: 5px;
    background: #e8c56d;
    padding: 2px;
    border-radius: 5px;
}

@media (max-width: 1023px) {
    #about .about_us {
        grid-template-columns: 1fr;
        padding: 2rem;
        gap: 3rem;
    }

    #about .about_us .text h1 {
        font-size: 1.3rem;
    }

    #about .about_us .text p {
        font-size: 1rem;
    }

    #about .about_us img {
        grid-area: 1;
    }

    /* /// */
    #about .address {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    #about .address img {
        grid-area: 1;
    }

    #about .address .text_address h2 {
        font-size: 2rem;
    }

    #about .address .text_address p {
        font-size: 1rem;
    }

    #about .address .text_address {
        padding-bottom: 2rem;
    }

    #about .full_address .address_2,
    #about .full_address .address_3 {
        width: 10rem;
    }
}