.copyrights {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr;
    background: #5a070b;
    color: #fff;
    font-weight: bold;
}

.copyrights {
    padding: 1rem 0;
}

.copyrights a {
    color: #e8c56d;
}

@media (max-width: 1023px) {
    .copyrights {
        font-size: 12px;
    }
}