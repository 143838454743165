#exhibtions {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
    margin-top: 5rem;
}

.exhibtions {
    background: #5a070b url("../../img/slide_background.png") no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 5rem;
    position: relative;
}

.exhibtions .after {
    position: absolute;
    top: 25%;
    left: 0;
    width: 10%;
}

.exhibtions .before {
    position: absolute;
    top: 40%;
    right: 0;
    transform: rotateY(180deg);
    width: 10%;
}

#exhibtions .exhibtions h2 {
    color: #fff;
    position: relative;
    width: max-content;
    padding-bottom: 5px;
    font-size: 3rem;
}

#exhibtions .exhibtions h2 span {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #e8c56d;
    border-radius: 50%;
}

#exhibtions .exhibtions .exhibtion {
    display: grid;
    margin: 3rem;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    justify-content: center;
    direction: ltr;
}

#exhibtions .exhibtions .exhibtion div {
    border: 1px solid #fff;
    font-weight: bold;
}

#exhibtions .exhibtions .exhibtion div.item1 {
    background: #7b111b;
}

#exhibtions .exhibtions .exhibtion div.item2 {
    background: #bc2b38;
}

#exhibtions .exhibtions .exhibtion div.item4 {
    background: #e8c56d;
}

#exhibtions .exhibtions .exhibtion div.item5 {
    background: #b2b0b1;
}

#exhibtions .exhibtions .exhibtion div.item3 p,
#exhibtions .exhibtions .exhibtion div.item4 p,
#exhibtions .exhibtions .exhibtion div.item5 p {
    color: #630a12;
}



#exhibtions .exhibtions .exhibtion div {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    border-radius: 1rem;
    gap: 1rem;
    align-items: center;
}

#exhibtions .exhibtions .exhibtion div p {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
}

@media (min-width: 1023px) {
    .item1 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
    }

    .item2 {
        grid-row: 2 / span 1;
        grid-column: 2 / span 2;
    }

    .item3 {
        grid-row: 3 / 3;
    }

    .item4 {
        grid-row: 3 / 3;
    }

    .item5 {
        grid-row: 3 / 3;
    }
}

@media (max-width: 1023px) {
    #exhibtions .exhibtions .exhibtion {
        grid-template-columns: 1fr 1fr;
    }

    #exhibtions .exhibtions h2 {
        font-size: 1.2rem;
    }

    #exhibtions .exhibtions .exhibtion div {
        padding: 2rem;
    }

    #exhibtions .exhibtions .exhibtion div p {
        font-size: 1rem;
    }

    .item5 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 2;
    }

}