#header {
    width: 100%;
    height: 100%;
    min-height: 75vh;
}

.header_content {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    background: url("../../img/slide_background.png") no-repeat;
    background-size: cover;
    position: relative;
}


.header_content .element_5 {
    width: 15%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
}


.left_right h1 {
    font-size: 5rem;
    color: #fff;
    text-align: center;
    font-weight: bold;
}

.left_right .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left_right p {
    color: #fff;
    text-align: center;
    font-weight: bold;
    direction: ltr;
    font-size: 1.3rem;
}

.left_right .bottom p:nth-child(1) {
    background: #e8c56d;
    padding: 1px 5px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    color: #5a070b;
}

.left_right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
    min-height: 50vh;
}

.left_right .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    opacity: 1;
}

.left_right .right {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    align-items: center;
    opacity: 1;
    min-height: 65vh;
}



.left_right .right img {
    width: 85%;
}

.left_right .top,
.left_right .bottom,
.left_right .right {
    transition: opacity 1s ease-in-out;
}

.left_right .visible {
    opacity: 1;
}

.left_right .hidden {
    opacity: 0;
}


@media (max-width: 768px) {
    #header {
        min-height: 80vh;
    }

    .left_right h1 {
        font-size: 1.5rem;
    }

    .left_right p {
        font-size: 1rem;
    }


    .header_content .element_5 {
        width: 30%;
    }

    .left_right {
        grid-template-columns: 1fr;
    }

    .left_right .left {
        height: fit-content;
        padding: 0 20px;
        margin-top: 2rem;
    }

    .left_right .right {
        min-height: 55vh;
        padding: 0 20px;
        justify-content: flex-start;
    }
}